import * as actions from './Actions.js';

import { Col, Row } from 'react-bootstrap';
import {
  PARAM_ADJUDICACOES,
  PARAM_ATA,
  PARAM_AUTORIZACOES_LICITACAO,
  PARAM_CERTIDOES,
  PARAM_CONTRATOS,
  PARAM_COTACOES,
  PARAM_DATAENTREGAPROPOSTAS_LICITACAO,
  PARAM_DESPESAS_ENTIDADES_DIFERENTES,
  PARAM_DOCUMENTOS_ATA,
  PARAM_EDITAL,
  PARAM_EMPENHOS,
  PARAM_HOMOLOGACOES,
  PARAM_IMPUGNACOES_RECURSOS,
  PARAM_LICITACOES_ENTIDADES_DIFERENTES,
  PARAM_MIDIAS,
  PARAM_OBRIGAR_IDENTIFICACAO_DOWNLOAD,
  PARAM_PARECERES,
  PARAM_PROPOSTA_PRECO,
  PARAM_PUBLICACOES_LICITACAO,
  PARAM_SOLICITACAO_NOTIFICACAO,
  PARAM_VISUALIZA_MIDIAS_ONLINE_EM_DESTAQUE
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';

import Arquivo from '../arquivo/Arquivo.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Component from 'react-pure-render/component';
import Empenhos from './../empenhos/Form.react.js';
import Helmet from 'react-helmet';
import LicitacaoDetalheAdjudicacao from './LicitacaoDetalheAdjudicacao.react.js';
import LicitacaoDetalheAta from './LicitacaoDetalheAta.react.js';
import LicitacaoDetalheAutorizacao from './LicitacaoDetalheAutorizacao.react.js';
import LicitacaoDetalheCertidao from './LicitacaoDetalheCertidao.react.js';
import LicitacaoDetalheContrato from './LicitacaoDetalheContrato.react.js';
import LicitacaoDetalheCotacao from './LicitacaoDetalheCotacao.react.js';
import LicitacaoDetalheHomologacao from './LicitacaoDetalheHomologacao.react.js';
import LicitacaoDetalheInformacao from './LicitacaoDetalheInformacao.react.js';
import LicitacaoDetalheMidia from './LicitacaoDetalheMidia.react.js';
import LicitacaoDetalheParecer from './LicitacaoDetalheParecer.react.js';
import LicitacaoDetalhePreco from './LicitacaoDetalhePreco.react.js';
import LicitacaoDetalhePublicacao from './LicitacaoDetalhePublicacao.react.js';
import LicitacaoDetalheRecurso from './LicitacaoDetalheRecurso.react.js';
import LicitacaoDetalheSolicitacao from './LicitacaoDetalheSolicitacao.react.js';
import Link from 'portaltransparencia-common/components/link/Link.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import ParametroService from '../parametros/ParametroService.js';
import PropTypes from 'prop-types';
import React from 'react';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import { addHttp } from 'portaltransparencia-common/libs/LinkExterno.js';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';
import { findContratosPorLicitacao as findContratos } from '../contratos/Actions.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils.js';

export class LicitacaoDetalhe extends Component {
  static propTypes = {
    licitacao: PropTypes.object.isRequired,
    midias: PropTypes.object.isRequired,
    arquivos: PropTypes.object.isRequired,
    publicacoes: PropTypes.object.isRequired,
    certidoes: PropTypes.object.isRequired,
    homologacoes: PropTypes.object.isRequired,
    cotacoes: PropTypes.object.isRequired,
    pareceres: PropTypes.object.isRequired,
    atas: PropTypes.object.isRequired,
    adjudicacoes: PropTypes.object.isRequired,
    precos: PropTypes.object.isRequired,
    contratos: PropTypes.object.isRequired,
    interessadosEditalDownload: PropTypes.object,
    location: PropTypes.object.isRequired,
    recursos: PropTypes.object.isRequired,
    numerosei: PropTypes.object,
    autorizacoes: PropTypes.object.isRequired,

    downloadArquivo: PropTypes.func.isRequired,
    findLicitacao: PropTypes.func.isRequired,
    findMidias: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findPublicacoes: PropTypes.func.isRequired,
    findCertidoes: PropTypes.func.isRequired,
    findHomologacoes: PropTypes.func.isRequired,
    findCotacoes: PropTypes.func.isRequired,
    findPareceres: PropTypes.func.isRequired,
    findAtas: PropTypes.func.isRequired,
    findPrecos: PropTypes.func.isRequired,
    findAdjudicacoes: PropTypes.func.isRequired,
    findContratos: PropTypes.func.isRequired,
    findRecursos: PropTypes.func.isRequired,
    findAutorizacoes: PropTypes.func.isRequired,
    findSei: PropTypes.func.isRequired,
    addErrorNotification: PropTypes.func.isRequired,

    paramMidias: PropTypes.string,
    paramAtas: PropTypes.string,
    paramAtasDocumentos: PropTypes.string,
    paramEdital: PropTypes.string,
    paramContratos: PropTypes.string,
    paramCotacoes: PropTypes.string,
    paramPareceres: PropTypes.string,
    paramPropostaPrecos: PropTypes.string,
    paramCertidoes: PropTypes.string,
    paramPublicacoes: PropTypes.string,
    paramHomologacoes: PropTypes.string,
    paramAdjudicacoes: PropTypes.string,
    paramEmpenhos: PropTypes.string,
    paramImpugnacoesRecursos: PropTypes.string,
    paramSolicitacao: PropTypes.string,
    paramObrigarIdentificacao: PropTypes.string,
    paramAutorizacoes: PropTypes.string,
    paramEmpenhosEntidadesDiferentes: PropTypes.string,
    paramLicitacoesEntidadesDiferentes: PropTypes.string,
    paramDataEntregaPropostas: PropTypes.string,

    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    const {
      findLicitacao,
      findMidias,
      findArquivos,
      location,
      findPublicacoes,
      findCotacoes,
      findPareceres,
      findAtas,
      findPrecos,
      findAdjudicacoes,
      findCertidoes,
      findHomologacoes,
      findContratos,
      findRecursos,
      findAutorizacoes,
      paramMidias,
      paramAtas,
      paramEdital,
      paramContratos,
      paramCotacoes,
      paramPareceres,
      paramPropostaPrecos,
      paramCertidoes,
      paramPublicacoes,
      paramHomologacoes,
      paramAdjudicacoes,
      paramAutorizacoes,
      paramVisualizaMidiaDestaque
    } = this.props;

    const entidade = getValueFromUrlSearchParams(location, 'entidade');
    const exercicio = getValueFromUrlSearchParams(location, 'exercicio');
    const tipoLicitacao = getValueFromUrlSearchParams(
      location,
      'tipoLicitacao'
    );
    const licitacao = getValueFromUrlSearchParams(location, 'licitacao');

    findLicitacao(entidade, exercicio, tipoLicitacao, licitacao).then(() => {
      if (paramMidias == 'S') {
        findMidias(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramEdital == 'S') {
        findArquivos(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramCertidoes == 'S') {
        findCertidoes(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramHomologacoes == 'S') {
        findHomologacoes(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramPublicacoes == 'S') {
        findPublicacoes(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramCotacoes == 'S') {
        findCotacoes(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramPareceres == 'S') {
        findPareceres(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramAtas == 'S') {
        findAtas(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramPropostaPrecos == 'S') {
        findPrecos(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramAdjudicacoes == 'S') {
        findAdjudicacoes(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramContratos == 'S') {
        findContratos(entidade, exercicio, tipoLicitacao, licitacao);
      }
      if (paramAutorizacoes == 'S') {
        findAutorizacoes(entidade, exercicio, tipoLicitacao, licitacao);
      }
      findRecursos(entidade, exercicio, tipoLicitacao, licitacao);
      if (paramVisualizaMidiaDestaque !== 'N') {
        this.searchNumeroSei();
      }
    });
  }

  searchEmpenho = function (
    licitacao,
    paramEmpenhosEntidadesDiferentes,
    paramLicitacoesEntidadesDiferentes
  ) {
    const { isIntegradoOxy } = this.props;
    let userInfo = StorageService.getItem('userInfo');
    const { entidade } = userInfo;
    const exercicio = licitacao.get('exercicio');
    const tipoLicitacao = licitacao.get('tipoLicitacao');
    const noLicitacao = licitacao.get('numeroLicitacao');
    const idLicitacao = licitacao.get('idOxy');

    let search =
      '?search=' +
      (isIntegradoOxy
        ? `licitacao.id==${idLicitacao}`
        : `anoLicitacao==${exercicio} and tipoLicitacao.compras==${tipoLicitacao} and noLicitacao==${noLicitacao} and entidadeLicitacao==${entidade.id}`);

    if (
      paramEmpenhosEntidadesDiferentes == 'N' ||
      paramLicitacoesEntidadesDiferentes == 'N'
    ) {
      search += ` and id.entidade==${entidade.id} `;
    }

    return search;
  };

  searchNumeroSei = () => {
    const { licitacao, isIntegradoOxy, findSei } = this.props;
    let filter = isIntegradoOxy
      ? `processoAdmId=${licitacao.get('processoAdmId')}`
      : `entidade=${licitacao.get('entidade')}&exercicio=${licitacao.get(
          'exercicio'
        )}&tipoLicitacao=${licitacao.get(
          'tipoLicitacao'
        )}&licitacao=${licitacao.get('licitacao')}`;

    findSei(filter);
  };

  download = item => {
    const { downloadArquivo, addErrorNotification } = this.props;
    if (this.possuiPermissao()) {
      return downloadArquivo(item);
    }

    return addErrorNotification(
      'Cadastre-se na solicitação de notificação para poder fazer download dos editais.'
    );
  };

  possuiLicitacaoUsuarioIdenficado = cadastrado => {
    const { licitacao } = this.props;

    return (
      cadastrado.get('entidade') == licitacao.get('entidade') &&
      cadastrado.get('exercicio') == licitacao.get('exercicio') &&
      cadastrado.get('tipoLicitacao') == licitacao.get('tipoLicitacao') &&
      cadastrado.get('licitacao') == licitacao.get('licitacao')
    );
  };

  possuiPermissao = () => {
    const {
      licitacao,
      paramObrigarIdentificacao,
      interessadosEditalDownload
    } = this.props;

    if (
      paramObrigarIdentificacao === 'S' &&
      licitacao.get('tipoCancelamento') &&
      (licitacao.get('tipoCancelamento').toUpperCase() === 'EM ANDAMENTO' ||
        licitacao.get('tipoCancelamento').toUpperCase() ===
          'AGUARDANDO ABERTURA')
    ) {
      let interessados = interessadosEditalDownload.filter(
        this.possuiLicitacaoUsuarioIdenficado
      );
      return interessados.length > 0;
    }

    return true;
  };

  renderNumeroSei = numeroSei => {
    const abreLink = link => {
      if (link) {
        window.open(addHttp(link));
      }
    };

    return (
      <tr key={numeroSei.get('idOxy') + numeroSei.get('id')}>
        <td>
          <Link
            value={numeroSei.get('numeroSei')}
            title={numeroSei.get('descricao')}
            icon={numeroSei.get('link') ? 'fa fa-external-link' : ''}
            data={numeroSei.get('link')}
            key={numeroSei.get('idOxy') + numeroSei.get('id')}
            onClick={abreLink}
          />
        </td>
      </tr>
    );
  };

  render() {
    const {
      licitacao,
      midias,
      arquivos,
      downloadArquivo,
      publicacoes,
      cotacoes,
      pareceres,
      atas,
      precos,
      certidoes,
      adjudicacoes,
      homologacoes,
      contratos,
      recursos,
      autorizacoes,
      numerosei,
      paramMidias,
      paramVisualizaMidiaDestaque,
      paramAtas,
      paramAtasDocumentos,
      paramEdital,
      paramContratos,
      paramCotacoes,
      paramPareceres,
      paramPropostaPrecos,
      paramCertidoes,
      paramPublicacoes,
      paramHomologacoes,
      paramAdjudicacoes,
      paramEmpenhos,
      paramImpugnacoesRecursos,
      paramSolicitacao,
      paramAutorizacoes,
      paramEmpenhosEntidadesDiferentes,
      location,
      history,
      paramObrigarIdentificacao,
      paramLicitacoesEntidadesDiferentes,
      paramDataEntregaPropostas,
      isIntegradoOxy
    } = this.props;

    if (licitacao.isEmpty()) {
      return null;
    }

    const arquivosList = arquivos
      .map(item => item.get('arquivoCompras'))
      .filter(item => !!item);

    let mostraPainelLateral = paramSolicitacao !== 'N';

    if (numerosei?.size > 0) {
      mostraPainelLateral = true;
    }

    return (
      <div>
        <Helmet title={`Licitação - #${licitacao.get('numeroLicitacao')}`} />
        <PageHeader
          location={location}
          textoDetalhe={`Licitação - ${licitacao.get(
            'descricao'
          )} ${licitacao.get('numeroLicitacao')}/${licitacao.get('exercicio')}`}
        />
        <CabecalhoEntidade />
        <BotaoImprimir />
        {mostraPainelLateral ? (
          <Row>
            <Col sm={8}>
              <LicitacaoDetalheInformacao
                mostraDataEntrega={paramDataEntregaPropostas === 'S'}
                licitacao={licitacao}
              />
            </Col>

            <Col sm={4}>
              {paramSolicitacao !== 'N' && (
                <LicitacaoDetalheSolicitacao
                  location={location}
                  paramObrigarIdentificacao={paramObrigarIdentificacao}
                />
              )}
              {numerosei?.size > 0 && (
                <Panel title="Sistema Eletrônico de Informações" isTable>
                  <h3>Processo Administrativo Licitatório:</h3>
                  <table
                    className={numerosei.size > 1 && 'panel-table fixed fancy'}
                  >
                    <tbody>{numerosei.map(this.renderNumeroSei)}</tbody>
                  </table>
                </Panel>
              )}
            </Col>
          </Row>
        ) : (
          <div>
            <LicitacaoDetalheInformacao
              mostraDataEntrega={paramDataEntregaPropostas === 'S'}
              licitacao={licitacao}
            />
          </div>
        )}
        <Panel expansible={false} isTable tableResponsive>
          <Tabs scrollVisibled>
            <Tab title="Edital/Documentos" hide={paramEdital != 'S'} active>
              <h4 className="mt-xs mb-xs center">
                <b>Edital e Outros Documentos</b>
              </h4>
              {licitacao.get('mostraLeiEdital') &&
                (licitacao.get('aplicaLei14133') === 'S' ? (
                  <div className="center">
                    Edital dispensável, conforme Lei 14133/21:
                    <br />
                    Art. 75. É dispensável a licitação e;
                    <br />
                    Art. 74. É inexigível a licitação quando inviável a
                    competição, em especial nos casos de...
                    <br />
                    <br />
                  </div>
                ) : (
                  <div className="center">
                    Edital dispensável, conforme Lei 8666/93:
                    <br />
                    Art. 24. É dispensável a licitação e;
                    <br />
                    Art. 25. É inexigível a licitação quando houver
                    inviabilidade de competição, em especial.
                    <br />
                    <br />
                  </div>
                ))}
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/documentos`}
              />
              <Arquivo
                arquivos={arquivosList}
                onDownload={this.download}
                sortByDate="ASC"
              />
            </Tab>

            <Tab
              title="Impugnações/Recursos"
              hide={paramImpugnacoesRecursos != 'S'}
            >
              <h4 className="mt-xs mb-xs center">
                <b>Impugnações e Recursos</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/recursos`}
              />
              <LicitacaoDetalheRecurso
                recursos={recursos}
                onDownload={downloadArquivo}
                isIntegradoOxy={isIntegradoOxy}
                history={history}
              />
            </Tab>

            <Tab title="Publicações" hide={paramPublicacoes != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Publicações dos Processos Licitatórios</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/publicacoes`}
              />
              <LicitacaoDetalhePublicacao
                publicacoes={publicacoes}
                onDownload={downloadArquivo}
              />
            </Tab>

            <Tab title="Cotações" hide={paramCotacoes != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Cotações</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/cotacoes`}
              />
              <LicitacaoDetalheCotacao
                cotacoes={cotacoes}
                onDownload={downloadArquivo}
              />
            </Tab>

            <Tab title="Propostas" hide={paramPropostaPrecos != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Propostas de Preços</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/propostaPrecos`}
              />
              <LicitacaoDetalhePreco
                precos={precos}
                onDownload={downloadArquivo}
              />
            </Tab>

            <Tab title="Certidões" hide={paramCertidoes != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Certidões</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/certidoes`}
              />
              <LicitacaoDetalheCertidao
                certidoes={certidoes}
                onDownload={downloadArquivo}
              />
            </Tab>

            <Tab title="Atas" hide={paramAtas != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Atas de Sessão e de Registros de Preços</b>
              </h4>
              {licitacao.get('mostraLeiAta') &&
                (licitacao.get('aplicaLei14133') === 'S' ? (
                  <div className="center">
                    Ata dispensável, conforme Lei 14133/21:
                    <br />
                    Art. 75. É dispensável a licitação e;
                    <br />
                    Art. 74. É inexigível a licitação quando inviável a
                    competição, em especial nos casos de...
                    <br />
                    <br />
                  </div>
                ) : (
                  <div className="center">
                    Ata dispensável, conforme Lei 8666/93:
                    <br />
                    Art. 24. É dispensável a licitação e;
                    <br />
                    Art. 25. É inexigível a licitação quando houver
                    inviabilidade de competição, em especial.
                    <br />
                    <br />
                  </div>
                ))}
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/atas`}
              />
              <LicitacaoDetalheAta
                atas={atas}
                onDownload={paramAtasDocumentos == 'S' && downloadArquivo}
                history={history}
              />
            </Tab>

            <Tab title="Pareceres" hide={paramPareceres != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Pareceres</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/pareceres`}
              />
              <LicitacaoDetalheParecer
                pareceres={pareceres}
                onDownload={downloadArquivo}
              />
            </Tab>

            <Tab title="Adjudicações" hide={paramAdjudicacoes != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Adjudicações do Edital</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/adjudicacoes`}
              />
              <LicitacaoDetalheAdjudicacao
                adjudicacoes={adjudicacoes}
                onDownload={downloadArquivo}
                history={history}
              />
            </Tab>

            <Tab title="Homologações" hide={paramHomologacoes != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Homologações e Ratificações</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/homologacoes`}
              />
              <LicitacaoDetalheHomologacao
                homologacoes={homologacoes}
                onDownload={downloadArquivo}
                history={history}
              />
            </Tab>

            <Tab title="Mídias" hide={paramMidias != 'S'}>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/midias`}
              />
              <LicitacaoDetalheMidia midias={midias} />
            </Tab>

            <Tab title="Contratos" hide={paramContratos != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Contratos</b>
              </h4>
              {licitacao.get('mostraLeiContrato') &&
                (licitacao.get('aplicaLei14133') === 'S' ? (
                  <div className="center">
                    Contrato dispensável, conforme Lei 14133/21.
                    <br />
                    <br />
                    Art. 95. O instrumento de contrato é obrigatório, salvo nas
                    seguintes hipóteses, em que a Administração poderá
                    substituí-lo por outro instrumento hábil, como
                    carta-contrato, nota de empenho de despesa, autorização de
                    compra ou ordem de execução de serviço:
                    <br />
                    <br />I - dispensa de licitação em razão de valor;
                    <br />
                    <br />
                  </div>
                ) : (
                  <div className="center">
                    Contrato dispensável, conforme Lei 8666/93: Regulamenta o
                    art. 37, inciso XXI, da Constituição Federal, institui
                    normas para licitações e contratos da Administração Pública
                    e dá outras providências.
                    <br />
                    <br />
                    Art. 62. O instrumento de contrato é obrigatório nos casos
                    de concorrência e de tomada de preços, bem como nas
                    dispensas e inexigibilidades cujos preços estejam
                    compreendidos nos limites destas duas modalidades de
                    licitação, e facultativo nos demais em que a Administração
                    puder substituí-lo por outros instrumentos hábeis, tais como
                    carta-contrato, nota de empenho de despesa, autorização de
                    compra ou ordem de execução de serviço.
                    <br />
                    <br />§ 4o É dispensável o &apos;termo de contrato&apos; e
                    facultada a substituição prevista neste artigo, a critério
                    da Administração e independentemente de seu valor, nos casos
                    de compra com entrega imediata e integral dos bens
                    adquiridos, dos quais não resultem obrigações futuras,
                    inclusive assistência técnica.
                    <br />
                    <br />
                  </div>
                ))}
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/contratos`}
              />
              <LicitacaoDetalheContrato
                contratos={contratos}
                history={history}
              />
            </Tab>

            <Tab title="Empenhos" hide={paramEmpenhos != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Empenhos</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/empenhos`}
              />
              <Empenhos
                filtroParam={this.searchEmpenho(
                  licitacao,
                  paramEmpenhosEntidadesDiferentes,
                  paramLicitacoesEntidadesDiferentes
                )}
                history={history}
                disableHeader
                ignoraExercicio={isIntegradoOxy}
              />
            </Tab>

            <Tab title="Autorizações" hide={paramAutorizacoes != 'S'}>
              <h4 className="mt-xs mb-xs center">
                <b>Autorizações de Prestação de Serviço</b>
              </h4>
              <NotasExplicativas
                pathname={`${this.props.location.pathname}/autorizacoes`}
              />
              <LicitacaoDetalheAutorizacao
                autorizacoes={autorizacoes}
                onDownload={downloadArquivo}
                history={history}
              />
            </Tab>
          </Tabs>
        </Panel>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    licitacao: state.licitacao.get('licitacao'),
    midias: state.licitacao.get('midias'),
    arquivos: state.licitacao.get('arquivos'),
    publicacoes: state.licitacao.get('publicacoes'),
    certidoes: state.licitacao.get('certidoes'),
    homologacoes: state.licitacao.get('homologacoes'),
    cotacoes: state.licitacao.get('cotacoes'),
    pareceres: state.licitacao.get('pareceres'),
    atas: state.licitacao.get('atas'),
    precos: state.licitacao.get('precos'),
    adjudicacoes: state.licitacao.get('adjudicacoes'),
    contratos: state.contrato.get('contratosLicitacao'),
    recursos: state.licitacao.get('recursos'),
    numerosei: state.licitacao.get('numerosei'),
    interessadosEditalDownload: state.licitacao.get(
      'interessadosEditalDownload'
    ),
    autorizacoes: state.licitacao.get('autorizacoes'),
    paramVisualizaMidiaDestaque: ParametroService.getValorParametro(
      state,
      PARAM_VISUALIZA_MIDIAS_ONLINE_EM_DESTAQUE
    ),
    paramMidias: ParametroService.getValorParametro(state, PARAM_MIDIAS),
    paramAtas: ParametroService.getValorParametro(state, PARAM_ATA),
    paramAtasDocumentos: ParametroService.getValorParametro(
      state,
      PARAM_DOCUMENTOS_ATA
    ),
    paramEdital: ParametroService.getValorParametro(state, PARAM_EDITAL),
    paramContratos: ParametroService.getValorParametro(state, PARAM_CONTRATOS),
    paramCotacoes: ParametroService.getValorParametro(state, PARAM_COTACOES),
    paramPareceres: ParametroService.getValorParametro(state, PARAM_PARECERES),
    paramPropostaPrecos: ParametroService.getValorParametro(
      state,
      PARAM_PROPOSTA_PRECO
    ),
    paramCertidoes: ParametroService.getValorParametro(state, PARAM_CERTIDOES),
    paramPublicacoes: ParametroService.getValorParametro(
      state,
      PARAM_PUBLICACOES_LICITACAO
    ),
    paramHomologacoes: ParametroService.getValorParametro(
      state,
      PARAM_HOMOLOGACOES
    ),
    paramAdjudicacoes: ParametroService.getValorParametro(
      state,
      PARAM_ADJUDICACOES
    ),
    paramEmpenhos: ParametroService.getValorParametro(state, PARAM_EMPENHOS),
    paramImpugnacoesRecursos: ParametroService.getValorParametro(
      state,
      PARAM_IMPUGNACOES_RECURSOS
    ),
    paramSolicitacao: ParametroService.getValorParametro(
      state,
      PARAM_SOLICITACAO_NOTIFICACAO
    ),
    paramObrigarIdentificacao: ParametroService.getValorParametro(
      state,
      PARAM_OBRIGAR_IDENTIFICACAO_DOWNLOAD
    ),
    paramAutorizacoes: ParametroService.getValorParametro(
      state,
      PARAM_AUTORIZACOES_LICITACAO
    ),
    paramEmpenhosEntidadesDiferentes: ParametroService.getValorParametro(
      state,
      PARAM_DESPESAS_ENTIDADES_DIFERENTES
    ),
    paramLicitacoesEntidadesDiferentes: ParametroService.getValorParametro(
      state,
      PARAM_LICITACOES_ENTIDADES_DIFERENTES
    ),
    paramDataEntregaPropostas: ParametroService.getValorParametro(
      state,
      PARAM_DATAENTREGAPROPOSTAS_LICITACAO
    ),
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  downloadArquivo,
  findContratos,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(LicitacaoDetalhe);
