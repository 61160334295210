import * as types from './Types.js';

export function findAditivo(
  entidade,
  exercicio,
  numero,
  sequencia,
  tipoAto,
  exercicioContrato,
  numeroContrato
) {
  return ({ fetch }) => ({
    type: types.ADITIVO,
    payload: {
      promise: fetch(
        `/api/aditivos/detalhes?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}&sequencia=${sequencia}&tipoAto=${tipoAto}&exercicioContrato=${exercicioContrato}&numeroContrato=${numeroContrato}`
      )
    }
  });
}

export function findArquivos(
  entidade,
  exercicio,
  numero,
  sequencia,
  exercicioContrato,
  numeroContrato
) {
  return ({ fetch }) => ({
    type: types.ADITIVO_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/aditivos/arquivos?entidade=${entidade}&exercicio=${exercicio}&numero=${numero}&sequencia=${sequencia}&exercicioContrato=${exercicioContrato}&numeroContrato=${numeroContrato}`
      )
    }
  });
}
